<template>
  <button :type="props.type" @click="onClick" :class="buttonClass">
    <slot>Default Label</slot>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'free',
    validator: (value: string) => ['sm', 'md', 'lg'].includes(value),
  },
  type: {
    type: String as () => 'submit' | 'button' | 'reset',
    default: 'button',
    validator: (value: string) => ['submit', 'button', 'reset'].includes(value),
  },
});
const emits = defineEmits(['click']);

const buttonClass = computed(() => {
  return `button button-${props.size}`;
});

const onClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped lang="scss">
.button {
  @apply text-gray-200 bg-cyan-400;
  &-sm {
    @apply pd-[10];
  }
  &-md {
    @apply pd-[20];
  }
  &-lg {
    @apply pd-[30];
  }
}
</style>

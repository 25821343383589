<template>
  <component :is="layout"></component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonData } from '@composables';
import ClearLayout from '@baseComponents/layout/ClearLayout.vue';
import DefaultLayout from '@baseComponents/layout/DefaultLayout.vue';

const route = useRoute();

const layout = computed(() =>
  route?.meta?.layout === 'ClearLayout' ? ClearLayout : DefaultLayout
);
useCommonData();
</script>

<style scoped lang="scss">
.index {
  @apply mt-1 font-sans;
}
h1 {
  @apply pd-[31] mg-[10] fs-[22];
}
</style>

<template>
  <MBaseDialog :isVisible="isVisible">
    <template #content>
      <div class="confirm-content">
        <p class="text" v-html="message"></p>
      </div>
    </template>
    <template #actions>
      <div class="confirm-btn-wrap">
        <AButton @click="handleCancel">취소</AButton>
        <AButton @click="handleConfirm" class="confirm-btn on">확인</AButton>
      </div>
    </template>
  </MBaseDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AButton } from '@atoms';
import { MBaseDialog } from '@molecules';
import { useAlertStore } from '@stores';

const alertStore = useAlertStore();

const isVisible = computed(() => alertStore.isConfirmVisible);
const message = computed(() => alertStore.message);

const handleConfirm = () => {
  alertStore.confirm(true);
};

const handleCancel = () => {
  alertStore.cancel();
};
</script>

<style scoped lang="scss">
.confirm-content {
  background: #fff;
  width: 100%;
  text-align: center;
}

.confirm-content .text {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 35px;
}

.confirm-btn-wrap {
  display: flex;
  align-items: center;
  gap: 0 8px;
}
.confirm-btn-wrap button {
  flex: 1;
  border-radius: 16px;
  background: #f7f7f7;
  height: 66px;
  font-size: 22px;
  @apply mobile:h-[52px] mobile:fs-[16];
  color: #242424;
  text-align: center;
  border-radius: 16px;
}

.confirm-btn.on {
  background: #638f43;
  color: #fff;
}

@media screen and (max-width: 884px) {
  .confirm-content .text {
    font-size: 1.125rem;
    font-weight: 500;
    padding-bottom: 25px;
  }

  .confirm-btn {
    height: 52px;
    font-size: 1rem;
    border-radius: 12px;
  }
}
</style>

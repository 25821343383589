import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAME } from '@constants';
import auth from '@infra/router/module/auth';
import clubIntroduction from '@infra/router/module/club-introduction';
import courseIntroduction from '@infra/router/module/course-introduction';
import reservation from '@infra/router/module/reservation';
import useInformation from '@infra/router/module/use-information';
import community from '@infra/router/module/community';
import myPage from '@infra/router/module/my-page';

const routes: RouteRecordRaw[] = [
  // {
  //   name: 'PaySmartro',
  //   path: '/smartro',
  //   component: () => import('@views/PaySmartro.vue'),
  //   meta: {
  //     // layout: 'ClearLayout',
  //   },
  // },
  {
    name: 'Test',
    path: '/test',
    component: () => import('@views/Test.vue'),
    meta: {},
  },
  {
    name: 'realNameReceivePopup',
    path: '/real-name-certificate/popup/:successFlag',
    component: () => import('@views/RealNameReceivePopup.vue'),
    props: true,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    name: 'realNameSendPopup',
    path: '/real-name-certificate/send-popup',
    component: () => import('@views/RealNameSendPopup.vue'),
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    name: ROUTE_NAME.Main,
    path: '/',
    component: () => import('@views/Index.vue'),
    meta: {
      title: '아름다운 경관과 준비된 서비스<br /> 고품격 골프 코스',
      subTitle: '아리지 컨트리 클럽에서의 멋진 추억을 가져가세요',
      hasSectionTitle: false,
    },
  },
  {
    name: ROUTE_NAME.ServiceTerms,
    path: '/service-terms',
    component: () => import('@views/copyright/ServiceTerms.vue'),
    meta: {
      breadcrumb: true,
      breadcrumbName: '이용약관',
    },
  },
  {
    name: ROUTE_NAME.PrivacyTerms,
    path: '/privacy-terms',
    component: () => import('@views/copyright/PrivacyTerms.vue'),
    meta: {
      breadcrumb: true,
      breadcrumbName: '개인정보취급방침',
    },
  },
  {
    name: ROUTE_NAME.RepairHomepage,
    path: '/repairs',
    component: () => import('@views/RepairHomepage.vue'),
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    name: ROUTE_NAME.NotFound,
    path: '/:pathMatch(.*)*',
    component: () => import('@views/NotFound.vue'),
    meta: {
      layout: 'ClearLayout',
      hasSectionTitle: false,
    },
  },
  auth,
  clubIntroduction,
  courseIntroduction,
  reservation,
  useInformation,
  community,
  myPage,
];

export default routes;

import { HttpInterface } from '@core/http';
import { CustomApiEndpoint } from '@api-endpoint';
import {
  FetchReservationAllListResponse,
  FetchReservationAllListPayload,
  FetchReservationListPayload,
  FetchReservationGroupListResponse,
} from '@dto';
export class CustomApiAdapter {
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }

  async fetchReservationAllList(
    payload: FetchReservationAllListPayload
  ): Promise<FetchReservationAllListResponse> {
    const response = await this.http.get<FetchReservationAllListResponse>(
      CustomApiEndpoint.fetchReservationAllList,
      payload
    );
    return response.data;
  }
  async fetchReservationGroupList(
    payload: FetchReservationListPayload
  ): Promise<FetchReservationGroupListResponse> {
    const response = await this.http.get<FetchReservationGroupListResponse>(
      CustomApiEndpoint.fetchReservationGroupList,
      payload
    );
    return response.data;
  }
}

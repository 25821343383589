<template>
  <div
    :class="
      $route.meta.navMenu && commonStore.isDesktop ? 'bottom-menu' : 'menu'
    "
  >
    <MobileNav v-if="commonStore.isMobile" :menuList="menuList" />
    <DesktopNav v-if="commonStore.isDesktop" :menuList="menuList" />
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@stores';
import DesktopNav from '@components/desktop/base/DesktopNav.vue';
import MobileNav from '@components/mobile/base/MobileNav.vue';
import routes from '@infra/router/routes';
const commonStore = useCommonStore();
const menuList = routes.filter((f) => f.meta?.navMenu);
</script>

<style scoped lang="scss">
.bottom-menu {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
}
.menu {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -20%);
  margin: auto;
}
</style>

import { AxiosAdapter, ReservationAdapter, CustomApiAdapter } from '@adapters';
import { FetchReservationDetailUseCase } from '@core/useCases';
import { computed, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import {
  FetchReservationDetailPayload,
  // FetchReservationDetailResponse,
  FetchReservationAllListResponse,
  FetchReservationGroupListResponse,
  TgResveAllTime,
  TgResveMember,
} from '@dto';
import { cloneDeep } from 'lodash-es';

export const useReservationList = () => {
  const axiosAdapter = new AxiosAdapter();
  const reservationAdapter = new ReservationAdapter(axiosAdapter);
  const customApiAdapter = new CustomApiAdapter(axiosAdapter);
  const totalItems = computed(() => {
    return reservationStatus.value?.total || 0;
  });
  const totalItemsPerPage = 20;
  const currentPage = ref<number>(1);
  const reservationStatus = ref<FetchReservationAllListResponse>();
  const reservationGroupStatus = ref<FetchReservationGroupListResponse>();
  const reservationStatusResvetimesOrigin = ref<TgResveAllTime[]>();
  const reservationStatusGroupResvetimesOrigin = ref<TgResveMember[]>();
  const params = reactive<{
    fromDate: string;
    toDate: string;
    limit: number;
    offset: number;
    div?: string;
  }>({
    fromDate: '',
    toDate: '',
    limit: 0,
    offset: 0,
    div: 'RESVE',
  });
  const searchParams = computed(() => {
    const fromDate = params.fromDate
      ? params.fromDate
      : dayjs().format('YYYY-MM-DD'); // 현재 날짜
    const toDate = params.toDate
      ? params.toDate
      : dayjs().add(3, 'month').format('YYYY-MM-DD'); // 3개월 후 날짜
    const limit = params.limit ? params.limit : totalItemsPerPage; // 페이지 당 항목 수
    const offset = params.offset
      ? params.offset
      : (currentPage.value - 1) * totalItemsPerPage; // 현재 페이지 오프셋
    const div = params.div ? params.div : 'ALL';
    return {
      fromDate,
      toDate,
      limit,
      offset,
      div,
    };
  });
  // const reservationDetail = ref<FetchReservationDetailResponse>();

  const fetchReservationList = async () => {
    reservationStatus.value = await customApiAdapter.fetchReservationAllList(
      searchParams.value
    );
    reservationStatusResvetimesOrigin.value = cloneDeep(
      reservationStatus.value.reservations
    );
  };
  const fetchReservationGroupList = async () => {
    reservationGroupStatus.value =
      await customApiAdapter.fetchReservationGroupList(searchParams.value);
    reservationStatusGroupResvetimesOrigin.value = cloneDeep(
      reservationGroupStatus.value.tgResveMembers
    );
  };
  const fetchReservationDetail = async (
    payload: FetchReservationDetailPayload
  ) => {
    const fetchReservationDetailUseCase = new FetchReservationDetailUseCase(
      reservationAdapter
    );
    return await fetchReservationDetailUseCase.execute(payload);
  };

  // const filterReservationList = (partDiv: number) => {
  //   const origin = reservationStatusResvetimesOrigin.value;
  //   if (!origin || !reservationStatus.value) return;
  //   if (partDiv) {
  //     reservationStatus.value.reservations = origin.filter(
  //       (item) => item.partDiv === `${partDiv}`
  //     );
  //   } else {
  //     reservationStatus.value.reservations = origin;
  //   }
  // };

  // watch(
  //   () => searchParams.value,
  //   async () => {
  //     console.log('watch');
  //     await fetchReservationList();
  //   }
  // );

  return {
    params,
    totalItems,
    totalItemsPerPage,
    currentPage,
    reservationStatus,
    reservationGroupStatus,
    fetchReservationList,
    // filterReservationList,
    fetchReservationDetail,
    fetchReservationGroupList,
  };
};

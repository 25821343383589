import { ref, reactive, computed, onMounted } from 'vue';
import { AxiosAdapter, UserAdapter } from '@adapters';
import {
  FetchUserDetailUseCase,
  FetchUserInfoUseCase,
  UpdateUserInfoUseCase,
} from '@core/useCases';
import { useAlertStore, useUserStore } from '@stores';
import {
  DORMANT_ACCOUNT_CONVERSION_CODE,
  GENDER_CODE,
  LUNAR_CODE,
  STATUS_YN,
} from '@core/enums';
import { isEmail, isMobileNumber, isPwd } from '@core/utils';

interface UserInfo {
  [key: string]: string | boolean | number;
  memberName: string;
  hpNo: string;
  webId: string;
  currentPassword: string;
  newPassword: string;
  birthDay: string;
  sexCode: string;
  lunarCode: string;
  areaCode: string;
  sigunCode: string;
  smsRecptnDiv: boolean;
  marktInfoAgreFlag: boolean;
  drmncyCnvrsCode: string;
  hpNoCertificationToken: string;
  crtfcId: number;
  email: string;
  emailId: string;
  emailAddress: string;
  homeAddr1: string;
  homeAddr2: string;
  homePostNo: string;
  memberNo: string;
  hpNo1: string;
  hpNo2: string;
  hpNo3: string;
  postRecptnFlag: boolean;
  emailRecptnAgre: boolean;
}

const genderOptions = [
  { value: GENDER_CODE.MALE, label: '남성' },
  { value: GENDER_CODE.FEMAIL, label: '여성' },
];
const lunarOptions = [
  { value: LUNAR_CODE.SOLAR, label: '양력' },
  { value: LUNAR_CODE.LUNAR, label: '음력' },
];
const marketingAgreeOptions = [
  { value: true, label: '동의함' },
  { value: false, label: '동의안함' },
];
const personalInfoExpiryOptions = [
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.YEAR, label: '1년' },
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT, label: '탈퇴시까지' },
];

export const useUserModify = () => {
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const alertStore = useAlertStore();
  const userStore = useUserStore();

  const newPasswordConfirm = ref('');
  const defaultPhone = ref<string>();
  const originData = ref();
  const userInfo = reactive<UserInfo>({
    memberName: userStore.memberName,
    hpNo: userStore.hpNo,
    webId: '',
    currentPassword: '',
    newPassword: '',
    birthDay: '',
    sexCode: '',
    lunarCode: LUNAR_CODE.SOLAR,
    areaCode: '',
    sigunCode: '',
    smsRecptnDiv: false,
    marktInfoAgreFlag: true,
    drmncyCnvrsCode: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT,
    hpNoCertificationToken: '',
    crtfcId: 0,
    email: '',
    emailId: '',
    emailAddress: '',
    homeAddr1: '',
    homeAddr2: '',
    homePostNo: '',
    memberNo: '',
    hpNo1: '',
    hpNo2: '',
    hpNo3: '',
    postRecptnFlag: false,
    emailRecptnAgre: false,
  });

  const validationPw = computed(
    () =>
      userInfo.newPassword !== '' &&
      !isPwd({ pwd: userInfo.newPassword, type: 'B', min: 10, max: 20 })
  );

  const isPasswordMatch = computed(() => {
    return (
      newPasswordConfirm.value !== '' &&
      userInfo.newPassword !== newPasswordConfirm.value
    );
  });
  const emailFormat = computed(() =>
    userInfo.emailId || userInfo.emailAddress
      ? `${userInfo.emailId}@${userInfo.emailAddress}`
      : ''
  );
  const validationEmail = computed(() => {
    return (
      (userInfo.emailId || userInfo.emailAddress) && !isEmail(emailFormat.value)
    );
  });
  const validationAddress = computed(() => {
    return (
      (userInfo.areaCode &&
        userInfo.sigunCode &&
        userInfo.homePostNo &&
        userInfo.homeAddr1 &&
        !userInfo.homeAddr2) ||
      (!userInfo.areaCode &&
        !userInfo.sigunCode &&
        !userInfo.homePostNo &&
        !userInfo.homeAddr1 &&
        userInfo.homeAddr2)
    );
  });

  // 회원정보 수정여부 판별
  const _getChangedKeys = <T extends object>(
    original: T,
    current: T
  ): (keyof T)[] => {
    const changedKeys: (keyof T)[] = [];

    for (const key in original) {
      if (Object.hasOwn(original, key) && Object.hasOwn(current, key)) {
        if (original[key] !== current[key]) {
          changedKeys.push(key);
        }
      }
    }

    return changedKeys;
  };
  const _hasChanges = <T extends object>(original: T, current: T): boolean => {
    const changedKeys = _getChangedKeys(original, current);
    return changedKeys.length > 0;
  };

  const _getTargetUserInfo = (originData: UserInfo) => {
    const keysToCopy = [
      'newPassword',
      'sexCode',
      'homeAddr1',
      'homeAddr2',
      'hpNo1',
      'hpNo2',
      'hpNo3',
      'emailId',
      'emailAddress',
      'smsRecptnDiv',
      'postRecptnFlag',
      'marktInfoAgreFlag',
      'emailRecptnAgre',
    ];
    return Object.keys(originData).reduce((targetUserInfo, key) => {
      if (keysToCopy.includes(key)) {
        targetUserInfo[key] = originData[key];
      }
      return targetUserInfo;
    }, {} as UserInfo);
  };

  // const _getTargetUserInfo = (originData: UserInfo) => {
  //   const targetUserInfo = {} as UserInfo;
  //   Object.keys(originData).forEach((key) => {
  //     if (
  //       key === 'newPassword' ||
  //       key === 'sexCode' ||
  //       key === 'areaCode' ||
  //       key === 'sigunCode' ||
  //       key === 'birthDay' ||
  //       key === 'lunarCode' ||
  //       key === 'hpNo' ||
  //       key === 'smsRecptnDiv' ||
  //       key === 'drmncyCnvrsCode'
  //     ) {
  //       targetUserInfo[key] = originData[key];
  //     }
  //   });
  //   return targetUserInfo;
  // };

  const validateForm = async () => {
    const {
      currentPassword,
      newPassword,
      areaCode,
      sigunCode,
      hpNo1,
      hpNo2,
      hpNo3,
      hpNoCertificationToken,
      crtfcId,
    } = userInfo;

    const targetUserInfo = _getTargetUserInfo(originData.value);
    const hpNo = hpNo1 + hpNo2 + hpNo3;
    // const valudateValue = {} as any;
    // Object.keys(originData.value).forEach((key) => {
    //   if (
    //     key === 'newPassword' ||
    //     key === 'sexCode' ||
    //     key === 'areaCode' ||
    //     key === 'sigunCode' ||
    //     key === 'birthDay' ||
    //     key === 'lunarCode' ||
    //     key === 'hpNo' ||
    //     key === 'smsRecptnDiv' ||
    //     key === 'drmncyCnvrsCode'
    //   ) {
    //     valudateValue[key] = originData.value[key];
    //   }
    // });

    // const changedKeys = Object.keys(valudateValue).filter((key: string) => {
    //   if (
    //     typeof valudateValue[key] === 'object' &&
    //     valudateValue[key] !== null
    //   ) {
    //     if (!userInfo[key]) {
    //       return false;
    //     }
    //     const changeObject = Object.keys(valudateValue[key]).length
    //       ? Object.keys(valudateValue[key]).some((field) => {
    //           return valudateValue[key][field] !== userInfo[key][field];
    //         })
    //       : valudateValue[key] !== userInfo[key];
    //     return changeObject;
    //   } else {
    //     return userInfo[key] !== valudateValue[key] && userInfo[key];
    //   }
    // });

    if (!currentPassword) {
      throw await alertStore.showAlertDialog('현재 비밀번호를 입력해주세요');
    }
    if (!_hasChanges(targetUserInfo, userInfo)) {
      throw await alertStore.showAlertDialog('변경된 사항이 없습니다');
    }
    if (newPassword) {
      if (!isPwd({ pwd: newPassword, type: 'B', min: 10, max: 20 })) {
        throw await alertStore.showAlertDialog(
          '비밀번호는 띄어쓰기 없는 영문자, 숫자, 특수문자 조합 10자 이상 사용 가능'
        );
      }
      if (userInfo.newPassword !== newPasswordConfirm.value) {
        throw await alertStore.showAlertDialog(
          '새로운 비밀번호를 확인해주시기 바랍니다'
        );
      }
    }
    if (!areaCode && !sigunCode) {
      throw await alertStore.showAlertDialog('거주지역을 입력해주세요');
    }
    if (defaultPhone.value !== hpNo && !crtfcId) {
      throw await alertStore.showAlertDialog(
        '새로운 핸드폰 번호입니다 인증해주세요'
      );
    }
    if (crtfcId) {
      if (!hpNo || !isMobileNumber(hpNo)) {
        throw await alertStore.showAlertDialog(
          '올바른 핸드폰 번호를 입력하여주세요'
        );
      }
      if (!hpNoCertificationToken) {
        throw await alertStore.showAlertDialog('인증번호를 인증하여주세요');
      }
    }
    return true;
  };

  const handleModify = async () => {
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }
    const updateUserInfoUseCase = new UpdateUserInfoUseCase(userAdapter);

    const {
      currentPassword,
      hpNoCertificationToken,
      smsRecptnDiv,
      drmncyCnvrsCode,
      newPassword,
      crtfcId,
      areaCode,
      sigunCode,
      hpNo1,
      hpNo2,
      hpNo3,
      homeAddr1,
      homeAddr2,
      homePostNo,
      marktInfoAgreFlag,
      postRecptnFlag,
      emailRecptnAgre,
    } = userInfo;

    try {
      await updateUserInfoUseCase.execute({
        currentPassword,
        hpNoCertificationToken,
        tgWebId: {
          webPwd: newPassword ? newPassword : null,
          tgMember: {
            hpNo: crtfcId ? hpNo1 + hpNo2 + hpNo3 : null,
            smsRecptnDiv: smsRecptnDiv ? STATUS_YN.YES : STATUS_YN.NO,
            tgMemberAddi: {
              email: emailFormat.value,
              drmncyCnvrsCode,
              areaCode,
              sigunCode,
              homeAddr1,
              homeAddr2,
              homePostNo,
              marktInfoAgreFlag,
              postRecptnDiv: postRecptnFlag ? 'HOME' : 'N',
              emailRecptnAgre,
            },
          },
        },
      });

      await alertStore.showAlertDialog('수정이 완료되었습니다');
    } catch (error) {
      throw error;
    }
  };

  const getUserInfo = async () => {
    const fetchUserDetailUseCase = new FetchUserDetailUseCase(userAdapter);
    const fetchUserInfoUseCase = new FetchUserInfoUseCase(userAdapter);

    const { phone } = await fetchUserDetailUseCase.execute();
    defaultPhone.value = phone;
    userInfo.hpNo = phone;
    userInfo.hpNo1 = phone.slice(0, 3);
    userInfo.hpNo2 = phone.slice(3, 7);
    userInfo.hpNo3 = phone.slice(7);

    const data = await fetchUserInfoUseCase.execute();
    userInfo.memberName = data.name;
    userInfo.webId = data.webId;
    userInfo.birthDay = data.tgMember.birthDay.split('-').join('');
    userInfo.sexCode = data.tgMember.sexCode;
    userInfo.lunarCode = data.tgMember.lunarCode;
    userInfo.areaCode = data.tgMember.tgMemberAddi.areaCode;
    userInfo.email = data.tgMember?.tgMemberAddi?.email || '';
    userInfo.homeAddr1 = data.tgMember?.tgMemberAddi?.homeAddr1
      ? data.tgMember?.tgMemberAddi?.homeAddr1
      : '';
    userInfo.homeAddr2 = data.tgMember?.tgMemberAddi?.homeAddr2
      ? data.tgMember?.tgMemberAddi?.homeAddr2
      : '';
    userInfo.homePostNo = data.tgMember?.tgMemberAddi?.homePostNo
      ? data.tgMember?.tgMemberAddi?.homePostNo
      : '';
    userInfo.emailId = data.tgMember?.tgMemberAddi?.email
      ? data.tgMember.tgMemberAddi.email.split('@')[0]
      : '';
    userInfo.emailAddress = data.tgMember?.tgMemberAddi?.email
      ? data.tgMember.tgMemberAddi.email.split('@')[1]
      : '';
    userInfo.sigunCode = data.tgMember.tgMemberAddi.sigunCode;
    userInfo.drmncyCnvrsCode = data.tgMember.tgMemberAddi.drmncyCnvrsCode;
    userInfo.marktInfoAgreFlag = data.tgMember.tgMemberAddi.marktInfoAgreFlag;
    userInfo.smsRecptnDiv =
      data.tgMember.smsRecptnDiv === STATUS_YN.YES ? true : false;
    userInfo.memberNo = data.tgMember.tgMemberships[0].memberNo;
    userInfo.postRecptnFlag =
      data.tgMember?.tgMemberAddi?.postRecptnDiv === 'HOME' ? true : false;
    userInfo.emailRecptnAgre = data.tgMember?.tgMemberAddi?.emailRecptnAgre;

    // originData.value = JSON.parse(JSON.stringify(userInfo));
    originData.value = { ...userInfo };
  };
  onMounted(async () => {
    await getUserInfo();
  });

  return {
    userInfo,
    genderOptions,
    lunarOptions,
    marketingAgreeOptions,
    personalInfoExpiryOptions,
    newPasswordConfirm,
    validationPw,
    validationEmail,
    validationAddress,
    isPasswordMatch,
    handleModify,
    getUserInfo,
  };
};

<template>
  <div class="sub-menu-bar">
    <ul class="sub-menu-list">
      <li
        v-for="(child, idx) in childMenu as Array<RouteRecordRaw>"
        :key="`${child.path}-${idx}`"
      >
        <router-link
          v-if="child?.meta?.breadcrumbName"
          :class="{
            mobileFont:
              route?.matched[0].name === ROUTE_NAME.MyPage &&
              commonStore.isMobile,
            active:
              route.name === child.name || child.name === route?.meta.subName,
          }"
          :to="{ name: child.name }"
          >{{ child?.meta.breadcrumbName }}</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { RouteRecordRaw, useRoute } from 'vue-router';
import { useCommonStore } from '@stores';
import { computed } from 'vue';
import { ROUTE_NAME } from '@constants';
import { useUserStore } from '@stores';

const userStore = useUserStore();
const commonStore = useCommonStore();
const route = useRoute();
const isAdmin = computed(() => userStore.grant === 'WEB_ADMIN');
const childMenu = computed(() =>
  route?.matched[0]?.children.filter((f) => {
    if (f?.meta?.isAdmin) {
      return isAdmin.value && f?.meta?.breadcrumb;
    } else {
      return f?.meta?.breadcrumb;
    }
  })
);
// const childMenu = computed(() =>
//   route?.matched[0]?.children.filter((f) => {
//     if (commonStore.isDesktop) {
//       return f?.meta?.breadcrumbName;
//     } else if (commonStore.isMobile) {
//       return !f?.meta?.deskView && f?.meta?.breadcrumbName;
//     }
//   })
// );
</script>

<style scoped lang="scss">
.sub-menu-bar {
  @apply w-full;
  height: 90px;
  border-bottom: 1px solid #ccc;
  @apply mobile:h-[50px];
  .sub-menu-list {
    width: 1200px;
    @apply flex flex-row m-auto h-full;
    @apply mobile:w-full;
    li {
      @apply mobile:flex-1;
      a {
        width: 150px;
        @apply flex flex-row justify-center items-center h-full;
        font-size: 22px;
        @apply mobile:w-full mobile:fs-[14];
        color: #959595;
      }
    }
  }
  .active {
    border-bottom: 2px solid #638f43;
    color: #638f43 !important;
  }
  .mobileFont {
    font-size: 12px !important;
  }
}
</style>

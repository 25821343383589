<template>
  <teleport to="body">
    <div v-if="isVisible" :class="backdrop">
      <div class="modal-container" :class="$attrs.class" :style="props.style">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps({
  direction: {
    type: String,
    default: 'center',
  },
  style: {
    type: Object,
    default: () => ({}),
  },
});

const isVisible = ref(false);

const backdrop = computed(() => {
  const containerCenter =
    props.direction === 'center'
      ? 'items-center justify-center'
      : 'justify-end';
  return ['backdrop', containerCenter];
});

// 부모 컴포넌트에서 접근하기 위한 함수.
const open = () => {
  isVisible.value = true;
};

const close = () => {
  isVisible.value = false;
};

// defineExpose 해줘야 부모함수에서 접근 가능
defineExpose({ open, close });
</script>

<style lang="scss" scoped>
.backdrop {
  @apply flex fixed bg-black bg-opacity-50 w-screen h-screen left-0 top-0;
  z-index: 20;
}
.modal-container {
  @apply flex flex-col bg-white min-w-[320px] origin-[-50%_-50%] w-4/5 px-16 py-28;
  @apply mobile:min-w-[320px] mobile:rounded-br-none;
  //mobile:origin-[-50%_0%] mobile:left-0 mobile:right-0 mobile:m-auto
  @apply desktop:w-[410px];
  background: transparent;
  width: 100%;
}
//.isResveMobile {
//  @apply mobile:w-4/5 mobile:bottom-0 mobile:h-auto mobile:overflow-y-scroll mobile:pb-80;
//}
</style>

<template>
  <div
    class="hero"
    :class="{
      'desktop-main': route.name === 'Main' && commonStore.isDesktop,
      'desktop-sub': route.name !== 'Main' && commonStore.isDesktop,
      'mobile-main': route.name === 'Main' && commonStore.isMobile,
      'mobile-sub': route.name !== 'Main' && commonStore.isMobile,
    }"
  >
    <template v-if="route.name === 'Main'">
      <div class="video-container">
        <video autoplay loop muted playsinline class="background-video">
          <source src="@assets/img/main/main.mp4" type="video/mp4" />
        </video>

        <div class="overlay">
          <div class="menu">
            <MobileHero v-if="commonStore.isMobile" />
            <DesktopHero v-if="commonStore.isDesktop" />
          </div>
        </div>
      </div>
      <!--      <OImageSlider alt="이미지" :options="slideOptions" :slideList="images">-->
      <!--        <template v-if="route.meta?.title" v-slot:title>-->
      <!--          <div-->
      <!--            class="title"-->
      <!--            :class="commonStore.isMobile ? 'mobile' : 'desktop'"-->
      <!--          >-->
      <!--            <span class="main-title" v-html="route.meta?.title"></span>-->
      <!--            <span-->
      <!--              v-if="route.meta?.subTitle"-->
      <!--              class="sub-title"-->
      <!--              v-html="route.meta?.subTitle"-->
      <!--            >-->
      <!--            </span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <template v-slot:menu>-->
      <!--          <div class="menu">-->
      <!--            <MobileHero v-if="commonStore.isMobile" />-->
      <!--            <DesktopHero v-if="commonStore.isDesktop" />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </OImageSlider>-->
    </template>
    <template v-else>
      <transition name="fade">
        <img
          style="
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
          "
          alt="서브메뉴 이미지"
          loading="lazy"
          :src="navImg"
          :key="navImg"
        />
      </transition>
      <div class="title" :class="commonStore.isMobile ? 'mobile' : 'desktop'">
        <span class="main-title" v-html="route.meta?.title"></span>
        <span
          v-if="route.meta?.subTitle"
          class="sub-title"
          v-html="route.meta?.subTitle"
        >
        </span>
      </div>
      <div class="menu">
        <MobileHero v-if="commonStore.isMobile" />
        <DesktopHero v-if="commonStore.isDesktop" />
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import DesktopHero from '@components/desktop/base/DesktopHero.vue';
import MobileHero from '@components/mobile/base/MobileHero.vue';
import { useCommonStore } from '@stores';
// import { OImageSlider } from '@organisms';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Main_Img01 from '@assets/img/main/main_img01.png';
import Main_Img02 from '@assets/img/main/main_img02.png';
import Main_Img03 from '@assets/img/main/main_img03.png';
import Main_Img04 from '@assets/img/main/main_img04.png';

const route = useRoute();

const commonStore = useCommonStore();
// const slideOptions = {
//   modules: ['Pagination', 'Navigation', 'Autoplay'],
// };
const images = computed(() => {
  return [Main_Img01, Main_Img02, Main_Img03, Main_Img04];
});
const navImg = computed(
  () =>
    route.matched.find((f) => f.name === route.name)?.meta.background ||
    route.meta.background ||
    images.value[0]
);
</script>

<style scoped lang="scss">
.hero {
  width: 100%;
  position: relative;
}
.desktop-main {
  height: 100vh;
}
.desktop-sub {
  height: 690px;
}
.mobile-main {
  height: 100vh;
}
.mobile-sub {
  height: 380px;
}
.menu {
  z-index: 99999999;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* 전체 화면 채우기 */
  overflow: hidden;
  .background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
//.title {
//  width: 100%;
//  z-index: 9999;
//  position: absolute;
//  pointer-events: none;
//  position: absolute;
//  display: flex;
//  flex-flow: column;
//  text-align: center;
//  .main-title {
//    font-size: 48px;
//    font-weight: bold;
//    color: white;
//    margin-bottom: 24px;
//  }
//  .sub-title {
//    font-size: 24px;
//    font-weight: bold;
//    color: white;
//  }
//}
.mobile {
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  .main-title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 14px;
  }
}
.desktop {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .main-title {
    font-size: 48px;
  }
  .sub-title {
    font-size: 24px;
  }
}

.fade-enter-active {
  animation: fade-in 0.5s;
}
.fade-leave-active {
  animation: fade-in 0.5s reverse;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<template>
  <div class="nav">
    <span class="nav-bar">
      <router-link
        v-for="(menu, idx) in menuList as Array<RouteRecordRaw>"
        :key="idx"
        :to="{ name: menu?.name }"
        :class="{ 'select-menu': select(menu) }"
      >
        <div class="menu-box">
          <img :src="menu?.meta?.subIcon" />
          <label class="title">{{ menu?.meta?.breadcrumbName }}</label>
        </div>
      </router-link>
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouteRecordRaw } from 'vue-router';

const { menuList } = defineProps({
  menuList: {
    type: Array,
    required: true,
  },
});

const route = useRoute();
const select = computed(() => {
  return (args: any) => {
    return args.children.some((s: any) => s.name === route.name);
  };
});
</script>

<style scoped lang="scss">
.nav {
  @apply flex flex-row flex-wrap justify-center;
  width: 320px;
  margin: auto;
  .nav-bar {
    @apply flex flex-row  flex-wrap justify-around gap-8;

    border-radius: 12px;
    a {
      @apply p-16 flex cursor-pointer justify-center;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(5px);
      width: 96px;
      height: 96px;
      .menu-box {
        @apply flex flex-col justify-center items-center;
      }
      .title {
        @apply text-xs text-center mt-4;
        color: white;
      }
    }
  }
  .select-menu {
    background: #638f43 !important;
  }
}
</style>

import { ROUTE_NAME } from '@constants';
import JoinMainImg from '@assets/img/auth/join_main_img.jpg';
const auth = {
  path: '/auth',
  redirect: '/auth/signin',
  meta: {
    breadcrumb: true,
  },
  children: [
    {
      name: ROUTE_NAME.Signin,
      path: 'signin',
      component: () => import('@views/auth/Signin.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.SigninAdmin,
      path: 'signin-admin',
      component: () => import('@views/auth/SigninAdmin.vue'),
      meta: {
        breadcrumbName: '관리자 로그인',
      },
    },
    {
      name: ROUTE_NAME.Signup,
      path: 'signup',
      component: () => import('@views/auth/Signup.vue'),
      meta: {
        background: JoinMainImg,
      },
    },
    {
      name: ROUTE_NAME.FindId,
      path: 'find-id',
      component: () => import('@views/auth/FindAccount.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.FindPassword,
      path: 'find-password',
      component: () => import('@views/auth/FindAccount.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.NewPasswordAccount,
      path: 'new-password-account',
      component: () => import('@views/auth/NewPasswordAccount.vue'),
      meta: {},
    },
  ],
};

export default auth;

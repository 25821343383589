import { AxiosAdapter, AuthAdapter } from '@adapters';
import { SignOutUseCase } from '@core/useCases';

export const useSignOut = () => {
  const axiosAdapter = new AxiosAdapter();
  const authAdapter = new AuthAdapter(axiosAdapter);
  const signOutUseCase = new SignOutUseCase(authAdapter);
  const handleSignOut = async () => {
    return await signOutUseCase.execute();
  };

  return {
    handleSignOut,
  };
};

<template>
  <div class="weather" @click="handleGoWeather">
    <img
      v-if="weatherImg"
      :src="`/weather/${weatherImg}.png`"
      alt="날씨 이미지"
      @click="handleGoWeather"
    />
    <span class="temperature">{{ weather?.t1h }}<b>&deg;</b></span>
  </div>
</template>

<script setup lang="ts">
import { useWeather } from '@composables';

const { weather, weatherImg } = useWeather();
const weatherUrl = import.meta.env.VITE_APP_WEATHER_URL;

const handleGoWeather = () => {
  window.open(weatherUrl, '_blank', 'noopener, noreferrer');
};
</script>

<style scoped lang="scss">
.weather {
  display: flex;
  align-items: center;
  gap: 0 5px;
  position: absolute;
  right: 66px;
  top: 95px;
}

.weather span {
  font-size: 34px;
  font-weight: 400;
}

.weather span b {
  font-size: 18px;
}

.weather img {
  width: 58px;
}
</style>

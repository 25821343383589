import { ref, computed, onMounted } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { AxiosAdapter, WeatherAdapter } from '@adapters';
import { FetchWeatherUseCase } from '@core/useCases';
import { FetchWeatherResponse } from '@dto';

dayjs.locale('ko');

export const useWeather = () => {
  const axiosAdapter = new AxiosAdapter();
  const weatherAdapter = new WeatherAdapter(axiosAdapter);
  const fetchWeatherUseCase = new FetchWeatherUseCase(weatherAdapter);
  const weather = ref<FetchWeatherResponse | Record<string, never>>({});

  const now = ref<string>(dayjs().format('YYYY.MM'));
  const now2 = ref<string>(dayjs().format('MM.DD'));
  const dayOfWeek = ref<string>(dayjs().format('dd'));
  const weatherUrl = import.meta.env.VITE_APP_WEATHER_URL;

  const weatherImg = computed(() => {
    const data = weather.value;

    let weatherIconName = 'Clear';
    if (!('pty' in data && 'sky' in data)) {
      return null;
    }

    if (data.pty === '0') {
      switch (data.sky) {
        case '1':
          weatherIconName = 'Clear';
          break;
        case '2':
          weatherIconName = 'Partly Cloudy';
          break;
        case '3':
          weatherIconName = 'Mostly Cloudy';
          break;
        case '4':
          weatherIconName = 'Cloudy';
          break;
        default:
          weatherIconName = 'Clear';
      }
    } else {
      switch (data.pty) {
        case '1':
          weatherIconName = 'Rain';
          break;
        case '2':
          weatherIconName = 'Sleet';
          break;
        case '3':
          weatherIconName = 'Snow';
          break;
        case '4':
          weatherIconName = 'Shower';
          break;
        case '5':
          weatherIconName = 'Rain Drops';
          break;
        case '6':
          weatherIconName = 'Rain Snow';
          break;
        case '7':
          weatherIconName = 'Snow Flurry';
          break;
        default:
          weatherIconName = 'Clear';
      }
    }

    return weatherIconName;
  });

  const fetchWeather = async () => {
    const validate = dayjs().minute() > 39;
    const formatDate = validate
      ? `${dayjs().format('YYYYMMDDHH')}40`
      : `${dayjs().subtract(1, 'hour').format('YYYYMMDDHH')}40`;
    weather.value = (await fetchWeatherUseCase.execute(
      formatDate
    )) as FetchWeatherResponse;
  };

  const handleGoToWeather = () => {
    window.open(weatherUrl, '_blank', 'noopener, noreferrer');
  };

  onMounted(fetchWeather);

  return {
    now,
    now2,
    dayOfWeek,
    weather,
    weatherImg,
    fetchWeather,
    handleGoToWeather,
  };
};

import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import {
  // useMetaStore,
  useCommonStore,
  useAlertStore,
  useUserStore,
  // useUserStore,
} from '@stores';
// import { ROUTE_NAME } from '@enums';

const Router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  linkExactActiveClass: 'route-active',
});

Router.beforeEach(async (to, _, next) => {
  const commonStore = useCommonStore();
  const userStore = useUserStore();
  const alertStore = useAlertStore();
  // const userStore = useUserStore();
  if (to.meta.requiresAuth && !commonStore.isSignIn) {
    await alertStore.showAlertDialog('로그인이 필요합니다');
    next('/auth/signin');
  } else if (to.meta.isReady) {
    await alertStore.showAlertDialog('준비중 입니다.');
  } else {
    if (userStore.grant === 'WEB_ADMIN' && to.meta.isAdminBlock) {
      return await alertStore.showAlertDialog('관리자는 접근하실수 없습니다');
    }
    //   to.name === ROUTE_NAME.Main
    //     ? commonStore.setMainSwiperActiveIndex(0)
    //     : commonStore.setMainSwiperActiveIndex(1);
    //
    //   const isFromSignup = from.name === ROUTE_NAME.Signup;
    //   if (isFromSignup) userStore.resetState();
    next();
  }
  // Router.afterEach((to) => {
  //   const metaStore = useMetaStore();
  //   metaStore.changeMeta(to.meta);
  // });
});

export default Router;

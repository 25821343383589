export const useScrollLock = () => {
  const lockScroll = () => {
    document.documentElement.style.overflow = 'hidden'; // html 태그에도 적용
    document.body.style.overflow = 'hidden';
  };
  const unLockScroll = () => {
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
  };

  return { lockScroll, unLockScroll };
};

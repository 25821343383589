<template>
  <div
    class="footer"
    :style="$route.name === ROUTE_NAME.Main && 'margin-top: 86px'"
  >
    <div class="footer-logo">
      <img src="@assets/img/main/header_logo.svg" alt="" />
    </div>
    <div class="footer-info">
      <ul class="info1">
        <li>
          <router-link :to="{ name: ROUTE_NAME.ServiceTerms }"
            >이용약관</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: ROUTE_NAME.PrivacyTerms }"
            >개인정보 취급방침</router-link
          >
        </li>
        <li style="cursor: pointer" @click="modal.open()">
          이메일무단수집거부
        </li>
        <li>
          <a
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1268191082"
            target="_blank"
            >사업자정보확인</a
          >
        </li>
        <li>
          <a
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1268191082"
            target="_blank"
            >통신판매신고(2009-경기여주)-0047</a
          >
        </li>
      </ul>
      <ul class="info2">
        <li>경기도 여주 가남읍 아리지그린길 68</li>
        <li>대표이사 김양원</li>
        <li>TEL. 031-887-5678</li>
        <li>FAX. 031-887-5619</li>
      </ul>
    </div>
  </div>
  <MModal ref="modal" class="modal">
    <template #modal-header>
      <div class="title">이메일 무단 수집 거부</div>
    </template>
    <template #modal-body>
      <div class="body">
        <ul>
          <li>
            본 화면 내에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의
            기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반시
            정보통신망법에 의해 형사 처벌됨을 유념 하시기 바랍니다.
          </li>
        </ul>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <AButton class="button on" @click="modal.close()">확인</AButton>
      </div>
    </template>
  </MModal>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@constants';
import { MModal } from '@molecules';
import { AButton } from '@atoms';
import { ref } from 'vue';
const modal = ref();
</script>

<style scoped lang="css">
.footer {
  width: 100%;
  height: 208px;
  display: flex;
  align-items: center;
  gap: 0 50px;
  padding: 0 80px;
  border-top: 4px solid #f7f7f7;
}

.footer-logo {
  height: 88px;
  width: 236px;
}

.footer-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-info {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  width: calc(100% - 236px);
}

.footer-info ul {
  display: flex;
  align-items: center;
  gap: 0 24px;
}

.footer-info ul li a {
  display: block;
}

.footer-info ul.info1 li {
  color: #6a6a6a;
  font-weight: 700;
}

.footer-info ul.info1 li.privacy {
  color: #638f43;
}

.footer-info ul.info2 li {
  color: #959595;
  font-weight: 500;
  position: relative;
}

.footer-info ul.info2 li:not(:last-child)::after {
  content: '';
  width: 1px;
  height: 12px;
  background: #959595;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
<style scoped lang="scss">
.modal {
  .title {
    @apply m-auto fs-[32] font-bold;
    color: #638f43;
  }
  .body {
    @apply py-32 px-16;
    border: 1px solid #c5c5c4;
    border-radius: 20px;
    ul li {
      @apply mb-4;
    }
  }
  .modal-footer {
    @apply w-full mt-12;
    .button {
      @apply w-full he-[66];
      border-radius: 16px;
    }
    .on {
      background: #638f43;
      color: #fff;
    }
  }
}
</style>

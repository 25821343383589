import { ROUTE_NAME } from '@constants';
import CommunityMainImg from '@assets/img/community/community_main_img.jpg';
import CommunityIcon from '@assets/img/community/community_icon.png';

const community = {
  path: '/community',
  redirect: '/community/notice',
  name: ROUTE_NAME.Community,
  meta: {
    breadcrumb: true,
    breadcrumbName: '커뮤니티',
    navMenu: true,
    background: CommunityMainImg,
    subIcon: CommunityIcon,
  },
  children: [
    {
      name: ROUTE_NAME.Notice,
      path: 'notice',
      component: () => import('@views/community/Notice.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '공지사항',
      },
    },
    {
      name: ROUTE_NAME.NoticeDetail,
      path: 'notice/:postId',
      component: () => import('@views/community/NoticeDetail.vue'),
      meta: {
        subName: ROUTE_NAME.Notice,
      },
    },
    {
      name: ROUTE_NAME.NoticeWrite,
      path: 'notice/write',
      component: () => import('@views/community/NoticeWrite.vue'),
      meta: {
        subName: ROUTE_NAME.Notice,
      },
    },
    {
      name: ROUTE_NAME.NoticeModify,
      path: 'notice/modify/:postId',
      component: () => import('@views/community/NoticeModify.vue'),
      meta: {
        subName: ROUTE_NAME.Notice,
      },
    },
    {
      name: ROUTE_NAME.Banner,
      path: 'banner',
      component: () => import('@views/community/Banner.vue'),
      meta: {
        isAdmin: true,
        breadcrumb: true,
        breadcrumbName: '배너관리',
      },
    },
    {
      name: ROUTE_NAME.BannerWrite,
      path: 'banner/write',
      component: () => import('@views/community/BannerWrite.vue'),
      meta: {
        subName: ROUTE_NAME.Banner,
      },
    },
    {
      name: ROUTE_NAME.BannerModify,
      path: 'banner/modify/:postId',
      component: () => import('@views/community/BannerModify.vue'),
      meta: {
        subName: ROUTE_NAME.Banner,
      },
    },
    // {
    //   name: ROUTE_NAME.NoticeWrite,
    //   path: 'notice/write',
    //   component: () => import('@views/community/NoticeWrite.vue'),
    // },
    // {
    //   name: ROUTE_NAME.NoticeView,
    //   path: 'notice/:postId',
    //   component: () => import('@views/community/NoticeView.vue'),
    // },
    // {
    //   name: ROUTE_NAME.HallOfFame,
    //   path: 'hall-of-fame',
    //   component: () => import('@views/community/HallOfFame.vue'),
    //   meta: {
    //     breadcrumb: true,
    //     breadcrumbName: '명예의전당',
    //   },
    // },
  ],
};
export default community;

<template>
  <MModal ref="modal" class="modal" :root-close="false">
    <template #modal-header>
      <div class="title">멤버십 QR 코드</div>
    </template>
    <template #modal-body>
      <div class="body">
        <VueQrcode
          :value="membership as string"
          :type="'image/png'"
          :color="{ dark: '#000000ff', light: '#ffffffff' }"
        />
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <button class="button on" @click="close">닫기</button>
      </div>
    </template>
  </MModal>
</template>
<script setup lang="ts">
import { MModal } from '@molecules';
import VueQrcode from 'vue-qrcode';
import { useCommonStore } from '@stores';
import { computed, ref, watch } from 'vue';
const commonStore = useCommonStore();
const modal = ref();
const close = () => {
  commonStore.setMembershipQrOpen(false);
  modal.value.close();
};
const membership = computed<string>(
  () => commonStore.getSessionStorage('membershipId') || ''
);
watch(
  () => commonStore.isMemberShipModalFlag,
  (value) => {
    if (value && membership.value) {
      modal.value.open();
    }
  }
);
</script>

<style scoped lang="scss">
.modal {
  .title {
    @apply m-auto fs-[20] font-bold;
    color: #638f43;
  }
  .body {
    @apply py-16 fs-[14] px-16 flex justify-center  he-[220];
    //border: 1px solid #c5c5c4;
    border-radius: 20px;
  }
  .modal-footer {
    @apply w-full mt-12;
    .button {
      @apply w-full he-[50];
      border-radius: 16px;
    }
    .on {
      background: #638f43;
      color: #fff;
    }
  }
}
</style>

import { ROUTE_NAME } from '@constants';
import MypageMainImg from '@assets/img/mypage/mypage_main_img.jpg';
import MypageIcon from '@assets/img/mypage/mypage_icon.png';

const mypage = {
  path: '/my-page',
  redirect: '/my-page/user-info-modify',
  name: ROUTE_NAME.MyPage,
  meta: {
    breadcrumb: true,
    breadcrumbName: '마이페이지',
    navMenu: true,
    background: MypageMainImg,
    subIcon: MypageIcon,
    isAdminBlock: true,
  },
  children: [
    {
      name: ROUTE_NAME.UserInfoModify,
      path: 'user-info-modify',
      component: () => import('@views/mypage/UserInfoModify.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '내정보',
      },
    },
    {
      name: ROUTE_NAME.GroupReserveInfo,
      path: 'group-reserve-info',
      component: () => import('@views/mypage/GroupReserveInfo.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '단체신청내역',
      },
    },
    {
      name: ROUTE_NAME.ScoreHandicapInfo,
      path: 'score-handicap-info',
      component: () => import('@views/mypage/ScoreHandicapInfo.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '스코어/핸디캡',
      },
    },

    {
      name: ROUTE_NAME.PenaltyInfo,
      path: 'penalty-info',
      component: () => import('@views/mypage/PenaltyInfo.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '위약현황',
      },
    },
    {
      name: ROUTE_NAME.CheckinInfo,
      path: 'checkin-info',
      component: () => import('@views/mypage/CheckinInfo.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '내장현황',
      },
    },
  ],
};
export default mypage;

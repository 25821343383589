<template>
  <div class="desktop-header">
    <h2 class="logo">
      <router-link :to="{ name: ROUTE_NAME.Main }">
        <img src="@assets/img/main/header_logo.svg" alt="" />
      </router-link>
    </h2>
    <div class="login" v-if="!commonStore.isSignIn">
      <ul>
        <li>
          <router-link :to="{ name: ROUTE_NAME.Signin }"> 로그인</router-link>
        </li>
        <li>
          <router-link :to="{ name: ROUTE_NAME.Signup }"> 회원가입</router-link>
        </li>
      </ul>
    </div>
    <div class="login" v-if="commonStore.isSignIn">
      <ul>
        <li @click="$router.push({ name: ROUTE_NAME.UserInfoModify })">
          <span>{{
            userStore.username ? `${userStore.username} 님` : ''
          }}</span>
        </li>
        <li @click="signOut">
          <span> 로그아웃 </span>
        </li>
      </ul>
    </div>
    <MWeatherInfo />
  </div>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@constants';
import { MWeatherInfo } from '@molecules';
import { useCommonStore, useUserStore } from '@stores';
import { useSignOut } from '@composables';
import { useRouter } from 'vue-router';
const { handleSignOut } = useSignOut();
const router = useRouter();
const commonStore = useCommonStore();
const userStore = useUserStore();
const signOut = async () => {
  const isSignOut = await handleSignOut();
  if (isSignOut) {
    await router.push({ name: ROUTE_NAME.Main });
  }
};
</script>

<style scoped lang="css">
.desktop-header {
  width: 100%;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 66px 0 66px;
  position: relative;
}

.desktop-header h2.logo a {
  display: block;
  img {
    width: 100%;
  }
}

.login ul {
  display: flex;
  align-items: center;
  gap: 0 20px;
  margin-bottom: 35px;
}

.login ul li {
  cursor: pointer;
  position: relative;
}

.login ul li a {
  color: #fff;
  position: relative;
}

.login ul li:first-child::after {
  content: '';
  width: 1px;
  height: 10px;
  background: #fff;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
}
</style>

<template>
  <div class="mobile-header">
    <h2 class="logo">
      <router-link :to="{ name: ROUTE_NAME.Main }">
        <img src="@assets/img/main/header_logo.svg" alt="로고이미지"
      /></router-link>
    </h2>
    <div class="login" v-if="!commonStore.isSignIn">
      <ul>
        <li class="division">
          <router-link :to="{ name: ROUTE_NAME.Signin }"> 로그인</router-link>
        </li>
        <li>
          <router-link :to="{ name: ROUTE_NAME.Signup }"> 회원가입</router-link>
        </li>
      </ul>
    </div>
    <div class="login" v-if="commonStore.isSignIn">
      <ul>
        <li class="division" @click="qrOpen">멤버십 QR</li>
        <li
          class="division"
          @click="$router.push({ name: ROUTE_NAME.UserInfoModify })"
        >
          <span>{{
            userStore.username ? `${userStore.username} 님` : ''
          }}</span>
        </li>
        <li @click="signOut">
          <span> 로그아웃 </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@constants';
import { useCommonStore, useUserStore } from '@stores';
import { useSignOut } from '@composables';
import { useRouter } from 'vue-router';
const { handleSignOut } = useSignOut();
const router = useRouter();
const commonStore = useCommonStore();
const userStore = useUserStore();
const signOut = async () => {
  const isSignOut = await handleSignOut();
  if (isSignOut) {
    await router.push({ name: ROUTE_NAME.Main });
  }
};
const qrOpen = async () => {
  commonStore.setMembershipQrOpen(true);
};
</script>

<style scoped lang="css">
.mobile-header {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 44px 16px 0 16px;
  position: relative;
}

.mobile-header h2.logo a {
  display: block;
  max-width: 121px;
  img {
    width: 100%;
  }
}

.login {
  display: flex;
  align-items: center;
}

.login ul {
  display: flex;
}
.login ul li {
  color: #fff;
  position: relative;
  font-size: 0.875rem;
}

.login ul .division {
  margin-right: 20px;
}

.login ul .division::after {
  content: '';
  width: 1px;
  height: 10px;
  background: #fff;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
}
</style>

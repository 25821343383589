<template>
  <MBaseDialog :isVisible="isVisible">
    <template #content>
      <div class="alert-content">
        <p class="text" v-html="message"></p>
      </div>
    </template>
    <template #actions>
      <div class="alert-btn-wrap">
        <AButton @click="hideAlert" class="alert-btn on">확인</AButton>
      </div>
    </template>
  </MBaseDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AButton } from '@atoms';
import { useAlertStore } from '@stores';
import { MBaseDialog } from '@molecules';

const alertStore = useAlertStore();

const isVisible = computed(() => alertStore.isAlertVisible);
const message = computed(() => alertStore.message);

const hideAlert = () => {
  alertStore.hideAlertDialog();
};
</script>

<style scoped lang="scss">
.alert-content {
  background: #fff;
  width: 100%;
  text-align: center;
}

.alert-content .text {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 35px;
}

.alert-btn-wrap {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.alert-btn {
  width: 100%;
  text-align: center;
  height: 66px;
  background: #f7f7f7;
  color: #242424;
  font-size: 22px;
  border-radius: 16px;
}

.alert-btn.on {
  background: #638f43;
  color: #fff;
}

@media screen and (max-width: 884px) {
  .alert-content .text {
    font-size: 1.125rem;
    font-weight: 500;
    padding-bottom: 25px;
  }

  .alert-btn {
    height: 52px;
    font-size: 1rem;
    border-radius: 12px;
  }
}
</style>

<template>
  <div>
    <MobileFooter v-if="commonStore.isMobile" ref="footer" class="footer" />
    <DesktopFooter v-if="commonStore.isDesktop" ref="footer" class="footer" />
  </div>
</template>

<script setup lang="ts">
import DesktopFooter from '@components/desktop/base/DesktopFooter.vue';
import MobileFooter from '@components/mobile/base/MobileFooter.vue';
import { useCommonStore } from '@stores';
const commonStore = useCommonStore();
</script>
<style scoped lang="scss"></style>

import { defineStore, DefineStoreOptions } from 'pinia';
import { useMediaQuery } from '@vueuse/core';
import { FetchListByGroupCodeResponse, FetchSystemInfoResponse } from '@dto';
import type { Ref } from 'vue';

// interface
interface CommonStoreState {
  totalSystemInfo: FetchSystemInfoResponse | null;
  isLoading: boolean;
  isSignIn: boolean;
  accessToken: string | null;
  membershipId: string;
  isMobileSize: Ref<boolean> | boolean;
  isShowScrollTop: boolean;
  areaCodeList: FetchListByGroupCodeResponse | [];
  // mainSwiperActiveIndex: number;
  isScrollBeyondThreshold: boolean;
  isHamburgerClicked: boolean;
  routeName: string;
  scrollY: number;
  membershipQrOpenModalFlag: boolean;
}
interface CommonStoreGetters {
  isTotalSystemInfo: (
    state: CommonStoreState
  ) => FetchSystemInfoResponse | null;
  getLocalStorage: (
    state: CommonStoreState
  ) => (params: string) => string | null;
  getSessionStorage: (
    state: CommonStoreState
  ) => (params: string) => string | null;
  isMemberShipModalFlag: (state: CommonStoreState) => boolean;
  isDesktop: (state: CommonStoreState) => boolean;
  isMobile: (state: CommonStoreState) => boolean;
  // isLogoCenter: (state: CommonStoreState) => boolean;
  // isMainFirstSwiper: (state: CommonStoreState) => boolean;
  isNotOverScrollY: (state: CommonStoreState) => boolean;
}
interface CommonStoreActions {
  setTotalSystemInfo: (info: FetchSystemInfoResponse) => void;
  setIsLoading: (loadingState: boolean) => void;
  setIsSignIn: (signInState: boolean) => void;
  setAccessToken: (token: string, membershipId?: string) => void;
  clearAccessToken: () => void;
  setMembershipQrOpen: (boolean: boolean) => void;
  setStorage: (repo: string, field: string, value: string) => void;
  removeStorage: (repo: RepoStorage, field: string) => void;
  setAreaCodeList: (areaCodeList: FetchListByGroupCodeResponse) => void;
  setScrollTop: (isShow: boolean) => void;
  // setMainSwiperActiveIndex: (swiperIndex: number) => void;
  setIsScrollBeyondThreshold: (isScrollBeyondThreshold: boolean) => void;
  setRouteName: (routeName: string) => void;
  setGnbOpen: () => void;
  setGnbClose: () => void;
  setScrollY: (scrollY: number) => void;
  setSignIn: (signInState: boolean) => void;
}

type CommonStoreOptions = DefineStoreOptions<
  string,
  CommonStoreState,
  CommonStoreGetters,
  CommonStoreActions
>;

type RepoStorage = 'sessionStorage' | 'localStorage';

const isSignInFromSessionStorage = sessionStorage.getItem('login');
const commonStoreId = 'common';
const commonStoreOptions: CommonStoreOptions = {
  id: commonStoreId,
  state: () => {
    return {
      totalSystemInfo: null,
      isLoading: false,
      isMobileSize: useMediaQuery('(max-width: 884px)') as Ref<boolean>,
      isSignIn: isSignInFromSessionStorage
        ? JSON.parse(isSignInFromSessionStorage)
        : false,
      accessToken: null,
      membershipId: '',
      isShowScrollTop: false,
      areaCodeList: [],
      mainSwiperActiveIndex: 0,
      isScrollBeyondThreshold: false,
      isHamburgerClicked: false,
      routeName: '',
      scrollY: 0,
      membershipQrOpenModalFlag: false,
    };
  },
  getters: {
    isTotalSystemInfo() {
      return this.totalSystemInfo;
    },
    isMemberShipModalFlag(state: CommonStoreState) {
      return state.membershipQrOpenModalFlag;
    },
    getLocalStorage: () => (params: string) => {
      return localStorage.getItem(params);
    },
    getSessionStorage: () => (params: string) => {
      return sessionStorage.getItem(params);
    },
    isDesktop: (state: CommonStoreState) => !state.isMobileSize as boolean,
    isMobile: (state: CommonStoreState) => state.isMobileSize as boolean,
    isNotOverScrollY(state) {
      return state.scrollY < 200;
    },
  },
  actions: {
    setTotalSystemInfo(info: FetchSystemInfoResponse) {
      this.totalSystemInfo = info;
    },
    setIsLoading(loadingState: boolean) {
      this.isLoading = loadingState;
    },
    setIsSignIn(signInState: boolean) {
      this.isSignIn = signInState;
    },
    setAccessToken(token: string, membershipId?: string) {
      this.accessToken = token;
      this.setIsSignIn(true);
      this.setStorage('sessionStorage', 'token', token);
      this.setStorage('sessionStorage', 'login', String(true));
      if (membershipId) {
        this.setStorage('sessionStorage', 'membershipId', membershipId);
      }
    },
    clearAccessToken() {
      this.accessToken = null;
      this.setIsSignIn(false);
      this.removeStorage('sessionStorage', 'membershipId');
      this.removeStorage('sessionStorage', 'token');
      this.setStorage('sessionStorage', 'login', String(false));
    },
    setStorage(repo: string, field: string, value: string) {
      repo === 'sessionStorage'
        ? sessionStorage.setItem(field, value)
        : localStorage.setItem(field, value);
    },
    removeStorage(repo: RepoStorage, field: string) {
      repo === 'sessionStorage'
        ? sessionStorage.removeItem(field)
        : localStorage.removeItem(field);
    },
    setAreaCodeList(areaCodeList: FetchListByGroupCodeResponse) {
      this.areaCodeList = areaCodeList;
    },
    setScrollTop(isShow: boolean) {
      this.isShowScrollTop = isShow;
    },
    // setMainSwiperActiveIndex(swiperIndex: number): void {
    //   this.mainSwiperActiveIndex = swiperIndex;
    // },
    setIsScrollBeyondThreshold(isScrollBeyondThreshold: boolean): void {
      this.isScrollBeyondThreshold = isScrollBeyondThreshold;
    },
    setRouteName(routeName: string): void {
      this.routeName = routeName;
    },
    setGnbOpen(): void {
      this.isHamburgerClicked = true;
    },
    setGnbClose(): void {
      this.isHamburgerClicked = false;
    },
    setScrollY(scrollY: number): void {
      this.scrollY = scrollY;
    },
    setSignIn(signInState: boolean) {
      this.isSignIn = signInState;
    },
    setMembershipQrOpen(boolean: boolean): void {
      this.membershipQrOpenModalFlag = boolean;
    },
  },
};
export const useCommonStore = defineStore(commonStoreId, commonStoreOptions);

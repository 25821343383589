<template>
  <div class="nav">
    <span class="nav-bar" :class="{ 'bottom-menu': $route.meta.navMenu }">
      <router-link
        v-for="(menu, idx) in menuList as Array<RouteRecordRaw>"
        :key="idx"
        :to="{ name: menu?.name }"
        :class="{ 'select-menu': select(menu) }"
      >
        <div class="menu-box">
          <img :src="menu?.meta?.subIcon" />
          <label class="title">{{ menu?.meta?.breadcrumbName }} </label>
        </div>
      </router-link>
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouteRecordRaw } from 'vue-router';
const route = useRoute();
const { menuList } = defineProps({
  menuList: {
    type: Array,
    required: true,
  },
});
const select = computed(() => {
  return (args: any) => {
    return args.children.some((s: any) => s.name === route.name);
  };
});
</script>

<style scoped lang="scss">
.nav {
  @apply flex flex-row justify-center;
  width: 1200px;
  height: 100px;
  margin: auto;
  .nav-bar {
    @apply flex flex-row py-14 w-full;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 12px;
    a {
      @apply px-16 flex cursor-pointer flex-1 justify-center;
      border-right: 1px solid white;
      &:last-child {
        border: none;
      }
      .menu-box {
        @apply flex flex-col justify-center items-center;
      }
      .title {
        @apply text-base text-center mt-4;
        color: white;
      }
    }
  }
  .bottom-menu {
    border-radius: 12px 12px 0 0;
    padding: 0;
    overflow: hidden;
  }
  .select-menu {
    background: #638f43;
  }
}
</style>

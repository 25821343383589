import { ROUTE_NAME } from '@constants';
import ReservationMainImg from '@assets/img/reservation/reservation_main_img.jpg';
import ReservationIcon from '@assets//img/reservation/reservation_icon.png';
const reservation = {
  name: ROUTE_NAME.Reservation,
  path: '/reservation',
  redirect: '/reservation/reservation',
  meta: {
    breadcrumb: true,
    breadcrumbName: '예약안내',
    navMenu: true,
    background: ReservationMainImg,
    subIcon: ReservationIcon,
    isAdminBlock: true,
  },
  children: [
    {
      name: ROUTE_NAME.GroupReservation,
      path: 'group-reservation-category',
      component: () =>
        import('@views/reservation/GroupReservationCategory.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '단체예약',
      },
    },
    {
      name: ROUTE_NAME.YearGroupReservationApplication,
      path: 'year-group-reservation-application',
      component: () =>
        import('@views/reservation/YearGroupReservationApplication.vue'),
      meta: {
        requiresAuth: true,
        subName: ROUTE_NAME.GroupReservation,
      },
    },
    {
      name: ROUTE_NAME.OneTimeGroupReservationApplication,
      path: 'one-time-group-reservation-application',
      component: () =>
        import('@views/reservation/OneTimeGroupReservationApplication.vue'),
      meta: {
        requiresAuth: true,
        subName: ROUTE_NAME.GroupReservation,
      },
    },
    {
      name: ROUTE_NAME.Reservation,
      path: 'reservation',
      component: () => import('@views/reservation/Reservation.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '실시간예약',
      },
    },
    {
      name: ROUTE_NAME.ReservationModify,
      path: 'reservation-modify',
      component: () => import('@views/reservation/ReservationModify.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: ROUTE_NAME.ReservationGroupFormation,
      path: 'reservation-group-formation',
      component: () =>
        import('@views/reservation/ReservationGroupFormation.vue'),
      meta: { requiresAuth: true },
    },
    {
      name: ROUTE_NAME.ReservationStatus,
      path: 'status',
      component: () => import('@views/reservation/ReservationStatus.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '예약확인/취소',
      },
    },
  ],
};

export default reservation;

import { computed, onMounted, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { useAlertStore, useCommonStore } from '@stores';
import { Calendar } from '@dto';
import { dayStringOfWeek } from '@core/utils';
import { RESERVATION_STATUS } from '@core/enums';
interface State {
  dates: any;
  year: number;
  month: number;
  calendar: Calendar[] | [];
  monthReserveList: Calendar[] | [];
}
export const calendarReadOnly = ref<boolean>(false);
export const useCalendar = (props: any, emit: any) => {
  const alertStore = useAlertStore();
  const commonStore = useCommonStore();
  const state: State = reactive({
    dates: [],
    year: props.year,
    month: props.month,
    calendar: props.calendar,
    monthReserveList: [],
  });

  const isReservable = (day: Calendar) => {
    if (!day.availableStatusByDate) return;
    const status = day.availableStatusByDate as RESERVATION_STATUS;

    return ![
      RESERVATION_STATUS.CLOSE,
      RESERVATION_STATUS.UNOPENED,
      RESERVATION_STATUS.PAST,
      RESERVATION_STATUS.ZERO,
      RESERVATION_STATUS.END,
      RESERVATION_STATUS.WAIT,
    ].includes(status);
  };

  const nowDate = computed(() => dayjs().format('YYYY-MM-DD'));

  const isToday = ({ bsnDate }: Calendar) => bsnDate === nowDate.value;
  const selectDate = async (day: Calendar) => {
    if (calendarReadOnly.value) {
      return;
    }
    console.log('select data === useCalendar');
    if (!day) {
      return;
    }
    if (day.availableStatusByDate === RESERVATION_STATUS.CLOSE) {
      return await alertStore.showAlertDialog('휴장입니다.');
    }
    if (
      day.availableStatusByDate === RESERVATION_STATUS.END ||
      day.availableStatusByDate === RESERVATION_STATUS.PAST
    ) {
      return await alertStore.showAlertDialog('마감되었습니다.');
    }
    if (day.availableStatusByDate === RESERVATION_STATUS.UNOPENED) {
      return await alertStore.showAlertDialog('오픈전입니다.');
    }
    if (day.reservationAvailableTimeCount === 0) {
      return await alertStore.showAlertDialog('예약 가능 타임이 없습니다.');
    }

    const selectDay = dayjs(`${state.year}-${state.month}-${day.bsnDe}`).format(
      'YYYY-MM-DD'
    );
    const weekDay = `${dayjs(selectDay).format('YYYY년 M월 D일')} (${dayStringOfWeek(dayjs(selectDay).day())})`;
    emit('selectDay', {
      bsnCode: day.bsnCode,
      selectDay,
      weekDay,
    });
  };

  const calendarData = () => {
    const [monthFirstDay, monthLastDate, lastMonthLastDate] =
      _getFirstDayLastDate(state.year, state.month);
    state.dates = _getMonthOfDays(
      monthFirstDay,
      monthLastDate,
      lastMonthLastDate
    );
  };

  const _getFirstDayLastDate = (year: number, month: number) => {
    const firstDay = new Date(year, month - 1, 1).getDay();
    const lastDate = new Date(year, month, 0).getDate();
    let lastYear = year;
    let lastMonth = month - 1;
    if (month === 1) {
      lastMonth = 12;
      lastYear -= 1;
    }
    const prevLastDate = new Date(lastYear, lastMonth, 0).getDate();
    return [firstDay, lastDate, prevLastDate];
  };

  const _getMonthOfDays = (
    monthFirstDay: number,
    monthLastDate: number,
    prevMonthLastDate: number
  ) => {
    monthLastDate;
    prevMonthLastDate;
    let day = 1;
    const dates = [];
    let weekOfDays = [];
    while (day <= state.monthReserveList.length) {
      if (day === 1) {
        for (let j = 0; j < monthFirstDay; j += 1) {
          weekOfDays.push('');
        }
      }
      weekOfDays.push(state.monthReserveList[day - 1]);
      if (weekOfDays.length === 7) {
        dates.push(weekOfDays);
        weekOfDays = [];
      }
      day += 1;
    }
    if (weekOfDays.length > 0) dates.push(weekOfDays);
    return dates;
  };

  /**
   * 예약 상태에 따른 텍스트 반환
   */
  const getReservationStatus = (date: Calendar) => {
    if (!date || !date.availableStatusByDate) {
      return '';
    }
    const reservationStatus = date.availableStatusByDate;
    const availTimeCount = date.reservationAvailableTimeCount;

    switch (reservationStatus) {
      case RESERVATION_STATUS.END:
      case RESERVATION_STATUS.PAST:
        return '';
      case RESERVATION_STATUS.UNOPENED:
        return '오픈전';
      case RESERVATION_STATUS.CLOSE:
        return '휴장';
      case RESERVATION_STATUS.WAIT:
        return ''; // 대기예약
      case RESERVATION_STATUS.TODAY:
        return 'Today';
      case RESERVATION_STATUS.ING:
        return availTimeCount
          ? commonStore.isMobile
            ? `예약`
            : '예약가능'
          : '';
      default:
        return '';
    }
  };

  onMounted(() => {
    state.monthReserveList =
      state.calendar.filter((f) => f.bsnMt === state.month) || [];
    calendarData();
  });

  return {
    state,
    isReservable,
    isToday,
    selectDate,
    getReservationStatus,
    calendarData,
  };
};

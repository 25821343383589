import { ROUTE_NAME } from '@constants';
import UseMainImg from '@assets/img/useInfo/use_main_img.jpg';
import UseIcon from '@assets/img/useInfo/useInfo_icon.png';
const useInformation = {
  name: ROUTE_NAME.UseInformation,
  path: '/use-information',
  redirect: '/use-information/usage-information',
  meta: {
    breadcrumb: true,
    breadcrumbName: '이용안내',
    navMenu: true,
    background: UseMainImg,
    subIcon: UseIcon,
  },
  children: [
    {
      name: ROUTE_NAME.UsageInformation,
      path: 'usage-information',
      component: () => import('@views/use-information/UsageInformation.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '이용안내',
      },
    },
    {
      name: ROUTE_NAME.Etiquette,
      path: 'etiquette',
      component: () => import('@views/use-information/Etiquette.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '에티켓',
      },
    },
    {
      name: ROUTE_NAME.FeeInformation,
      path: 'fee-information',
      component: () => import('@views/use-information/FeeInformation.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '요금안내',
      },
    },
    {
      name: ROUTE_NAME.TermsOfUse,
      path: 'terms-of-use',
      component: () => import('@views/use-information/TermsOfUse.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '이용규정',
      },
    },
    // {
    //   name: ROUTE_NAME.LocalRule,
    //   path: 'local-rule',
    //   component: () => import('@views/use-information/LocalRule.vue'),
    //   meta: {
    //     breadcrumb: true,
    //     breadcrumbName: '로컬룰',
    //   },
    // },
  ],
};

export default useInformation;

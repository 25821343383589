<template>
  <teleport to="body">
    <div v-if="props.isVisible" :class="backdrop">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useScrollLock } from '@composables';

const props = defineProps({
  direction: {
    type: String,
    default: 'center',
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const { lockScroll, unLockScroll } = useScrollLock();

const backdrop = computed(() => {
  const containerPosition =
    props.direction === 'center'
      ? 'items-center justify-center'
      : 'justify-end';
  return ['backdrop', containerPosition];
});

watch(
  () => props.isVisible,
  (value) => {
    value ? lockScroll() : unLockScroll();
  }
);
</script>

<style lang="scss" scoped>
.backdrop {
  @apply flex fixed bg-black bg-opacity-80 z-30 w-screen h-screen left-0 top-0;
}
.content {
  @apply flex flex-col bg-white min-w-[320px] origin-[-50%_-50%] w-4/5;
  @apply mobile:min-w-[320px] mobile:rounded-br-none mobile:origin-[-50%_0%] mobile:left-0 mobile:right-0 mobile:m-auto;
  max-width: 440px;
  position: absolute;
  border-radius: 20px;
  padding: 40px 25px;
}

@media screen and (max-width: 884px) {
  .content {
    padding: 30px 20px;
  }
}
</style>

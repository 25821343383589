import { ROUTE_NAME } from '@constants';
import ClubInfoMain from '@assets/img/clubInfo/club_info_main.jpg';
import ClubIcon from '@assets/img/clubInfo/club_icon.png';
const ClubInfo = {
  path: '/club-introduction',
  redirect: '/club-introduction/greeting',
  name: ROUTE_NAME.ClubInfo,
  meta: {
    breadcrumb: true,
    breadcrumbName: '클럽소개',
    navMenu: true,
    background: ClubInfoMain,
    subIcon: ClubIcon,
  },
  children: [
    {
      name: ROUTE_NAME.Greeting,
      path: 'greeting',
      component: () => import('@views/club-introduction/Greeting.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '인사말',
      },
    },
    {
      name: ROUTE_NAME.ClubIntroduction,
      path: 'clubIntroduction',
      component: () => import('@views/club-introduction/ClubIntroduction.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '클럽소개',
      },
    },
    {
      name: ROUTE_NAME.Location,
      path: 'location',
      component: () => import('@views/club-introduction/Location.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '오시는길',
      },
    },
  ],
};

export default ClubInfo;

import { computed, reactive, ref, watch } from 'vue';
import { AxiosAdapter, UserAdapter } from '@adapters';
import { SignUpUsecase } from '@core/useCases';
import { useCheckIdDuplicate } from '@composables';
import { useAlertStore, useUserStore } from '@stores';
import {
  DORMANT_ACCOUNT_CONVERSION_CODE,
  GENDER_CODE,
  LUNAR_CODE,
  POST_RECPTN_DIV,
  STATUS_YN,
} from '@core/enums';
import { getBirthdayWithHyphen } from '@core/utils';
import { SignUpPayload } from '@dto';
const genderOptions = [
  { value: GENDER_CODE.MALE, label: '남성' },
  { value: GENDER_CODE.FEMAIL, label: '여성' },
];
const lunarOptions = [
  { value: LUNAR_CODE.SOLAR, label: '양력' },
  { value: LUNAR_CODE.LUNAR, label: '음력' },
];
const marketingAgreeOptions = [
  { value: true, label: '동의함' },
  { value: false, label: '동의안함' },
];
const personalInfoExpiryOptions = [
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.YEAR, label: '1년' },
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT, label: '탈퇴시까지' },
];

export const useSignUp = () => {
  // ================================================================= 이하 회원가입시 약관동의 관련 코드
  const allAgreeCheck = ref(false);
  const usageAgreeCheck = ref(false);
  const privatePolicyCheck = ref(false);
  const privateAgreeCheck = ref(false);
  const marketingAgreeCheck = ref(false);

  const agrees = reactive([
    {
      id: 'usageAgreeCheck',
      title: '회원약관',
      value: usageAgreeCheck,
      required: true,
    },
    {
      id: 'privatePolicyCheck',
      title: '개인정보취급방침',
      value: privatePolicyCheck,
      required: true,
    },
    {
      id: 'privateAgreeCheck',
      title: '개인정보제공동의',
      value: privateAgreeCheck,
      required: true,
    },
    {
      id: 'marketingAgreeCheck',
      title: '개인정보의 수집 이용제공',
      value: marketingAgreeCheck,
      required: false,
    },
  ]);

  const handleChangeAllCheck = (checked: Event | boolean) => {
    const isChecked =
      typeof checked === 'boolean' ? checked : allAgreeCheck.value;
    allAgreeCheck.value =
      usageAgreeCheck.value =
      privatePolicyCheck.value =
      privateAgreeCheck.value =
      marketingAgreeCheck.value =
        isChecked;
  };

  const isRequiredAgreeAllTrue = computed(() => {
    return (
      usageAgreeCheck.value &&
      privatePolicyCheck.value &&
      privateAgreeCheck.value
    );
  });

  watch([usageAgreeCheck, privateAgreeCheck, marketingAgreeCheck], () => {
    allAgreeCheck.value =
      usageAgreeCheck.value &&
      privatePolicyCheck.value &&
      privateAgreeCheck.value &&
      marketingAgreeCheck.value;
  });

  // ================================================================= 이하 회원가입시 정보입력 관련 코드
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const signUpUsecase = new SignUpUsecase(userAdapter);
  const { handleCheckIdDuplicate: checkIdDuplicate } = useCheckIdDuplicate();
  const alertStore = useAlertStore();
  const userStore = useUserStore();
  const webPwdConfirm = ref('');
  const userInfo: SignUpPayload = reactive({
    memberName: userStore.memberName,
    hpNo: userStore.hpNo,
    webId: '',
    webPwd: '',
    birthDay: '',
    sexCode: GENDER_CODE.MALE,
    lunarCode: LUNAR_CODE.SOLAR,
    areaCode: '',
    sigunCode: '',
    homePostNo: '',
    homeAddr1: '',
    homeAddr2: '',
    email: '',
    emailRecptnAgre: false,
    smsRecptnDiv: userStore.isPrivacyUseAgreement
      ? STATUS_YN.YES
      : STATUS_YN.NO,
    marktInfoAgreFlag: true,
    drmncyCnvrsCode: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT,
    postRecptnDiv: POST_RECPTN_DIV.N,
    certificationTransaction: {
      userci: '',
      userdi: '',
    },
  });
  const isAvailableId = ref(false);
  const isPasswordMatch = computed(() => {
    return userInfo.webPwd === webPwdConfirm.value;
  });

  const handleCheckIdDuplicate = async () => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,20}$/;
    if (userInfo.webId === '') {
      await alertStore.showAlertDialog('아이디를 입력해주세요');
      return;
    }
    if (!regex.test(userInfo.webId)) {
      await alertStore.showAlertDialog(
        '영문, 숫자 혼합하여 4자리 이상 20자리 이하 로 입력하여주세요'
      );
      return;
    }
    isAvailableId.value = await checkIdDuplicate(userInfo.webId);
    if (isAvailableId.value) {
      await alertStore.showAlertDialog('사용 가능한 아이디입니다.');
    }
  };

  const handleSignUp = async () => {
    try {
      await signUpUsecase.execute({
        ...userInfo,
        birthDay: getBirthdayWithHyphen(userInfo.birthDay),
      });
      await alertStore.showAlertDialog('회원가입이 완료되었습니다.');
    } catch (error) {
      throw await alertStore.showAlertDialog('잘못된 정보입니다');
    }
  };

  return {
    // 약관동의 return
    agrees,
    isRequiredAgreeAllTrue,
    handleChangeAllCheck,

    // 회원가입 약관동의 return
    allAgreeCheck,
    usageAgreeCheck,
    privatePolicyCheck,
    privateAgreeCheck,
    marketingAgreeCheck,

    // 회원가입 폼 return
    userInfo,
    webPwdConfirm,
    genderOptions,
    lunarOptions,
    marketingAgreeOptions,
    personalInfoExpiryOptions,
    isAvailableId,
    isPasswordMatch,
    handleSignUp,
    handleCheckIdDuplicate,
  };
};

<template>
  <nav class="nav-background">
    <h1 v-if="route?.matched.length" class="title">
      {{ route?.matched[0].meta?.breadcrumbName }}
    </h1>
    <ResponsiveNav />
  </nav>
</template>
<script setup lang="ts">
import ResponsiveNav from '@components/responsive/ResponsiveNav.vue';
import { useRoute } from 'vue-router';
const route = useRoute();
</script>

<style scoped lang="scss">
.title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  color: #ffffff;
  font-weight: bold;
}
</style>

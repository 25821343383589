<template>
  <div class="header">
    <MobileHeader v-if="commonStore.isMobile" ref="header" />
    <DesktopHeader v-if="commonStore.isDesktop" ref="header" />
  </div>
</template>

<script setup lang="ts">
import MobileHeader from '@components/mobile/base/MobileHeader.vue';
import DesktopHeader from '@components/desktop/base/DesktopHeader.vue';
import { useCommonStore } from '@stores';

const commonStore = useCommonStore();
</script>

<style scoped lang="scss">
.header {
  @apply fixed w-full;
  position: fixed;
  color: #fff;
  z-index: 19;
}
</style>

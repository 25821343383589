import { AxiosAdapter, UserAdapter } from '@adapters';
import { CheckIdDuplicateUsecase } from '@core/useCases';
import { useAlertStore } from '@stores';

export const useCheckIdDuplicate = () => {
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const checkIdDuplicateUsecase = new CheckIdDuplicateUsecase(userAdapter);
  const alertStore = useAlertStore();

  const handleCheckIdDuplicate = async (webId: string) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,20}$/;
    if (webId === '') {
      await alertStore.showAlertDialog('아이디를 입력해주세요');
      return false;
    }
    if (!regex.test(webId)) {
      await alertStore.showAlertDialog(
        '영문, 숫자 혼합하여 4자리 이상 20자리 이하 로 입력하여주세요'
      );
      return false;
    }
    return await checkIdDuplicateUsecase.execute(webId);
  };

  return {
    handleCheckIdDuplicate,
  };
};

import { ROUTE_NAME } from '@constants';
import CourseMainImg from '@assets/img/course/course_main_img.jpg';
import CourseIcon from '@assets/img/course/course_icon.png';
// import SunCourseMainImg from '@assets/img/course/sun_course_main_img.jpg';
// import MoonCourseMainImg from '@assets/img/course/moon_course_main_img.jpg';
// import StarCourseMainImg from '@assets/img/course/star_course_main_img.jpg';
const courseInfo = {
  path: '/course-info',
  redirect: '/course-info/course-introduction',
  name: ROUTE_NAME.CourseInfo,
  meta: {
    breadcrumb: true,
    breadcrumbName: '코스안내',
    navMenu: true,
    background: CourseMainImg,
    subIcon: CourseIcon,
  },
  children: [
    {
      name: ROUTE_NAME.CourseIntroduction,
      path: 'course-introduction',
      component: () =>
        import('@views/course-introduction/CourseIntroduction.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '코스소개',
      },
    },
    {
      name: ROUTE_NAME.SunCourse,
      path: 'sun-course',
      component: () => import('@views/course-introduction/SunCourse.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '햇님코스',
        // background: SunCourseMainImg,
        // deskView: true,
      },
    },
    {
      name: ROUTE_NAME.MoonCourse,
      path: 'moon-course',
      component: () => import('@views/course-introduction/MoonCourse.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '달님코스',
        // background: MoonCourseMainImg,
        // deskView: true,
      },
    },
    {
      name: ROUTE_NAME.StarCourse,
      path: 'star-course',
      component: () => import('@views/course-introduction/StarCourse.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '별님코스',
        // background: StarCourseMainImg,
        // deskView: true,
      },
    },
  ],
};

export default courseInfo;

<template>
  <ABaseModal ref="modal">
    <div class="modal" :class="$attrs.class">
      <button
        v-if="rootClose"
        @click="close()"
        class="modal-footer-button close"
      >
        <img src="@assets/img/modal_close.png" style="transform: scale(0.8)" />
      </button>
      <div class="modal-header">
        <slot name="modal-header" />
      </div>
      <div class="modal-nav">
        <slot name="modal-nav" />
      </div>
      <div class="modal-body">
        <slot name="modal-body" />
      </div>
      <div class="modal-captcha">
        <slot name="modal-captcha" />
      </div>
      <div class="modal-sub-footer">
        <slot name="modal-sub-footer" />
      </div>
      <div class="modal-footer">
        <slot name="modal-footer"></slot>
      </div>
    </div>
  </ABaseModal>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { ABaseModal } from '@atoms';
import { useScrollLock } from '@composables';

const { lockScroll, unLockScroll } = useScrollLock();
defineProps({
  title: {
    type: String,
    default: 'Modal Title',
  },
  subTitle: {
    type: String,
    default: 'Modal Subtitle',
  },
  rootClose: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(['complete']);

const modal = ref();
const argument = ref();

const open = (args: any) => {
  lockScroll();
  modal.value.open();
  argument.value = args;
};

const close = ({ complete = false, args = null } = {}) => {
  unLockScroll();
  if (complete) {
    emits('complete', args);
  }
  modal.value.close();
};

defineExpose({
  open,
  close,
  argument,
});
</script>

<style lang="scss" scoped>
.none {
  .modal-header {
    padding: 0 !important;
  }
}
.modal {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  z-index: 1000;
  @apply flex flex-col py-40 px-24 w-[440px] justify-between;
  @apply mobile:w-[335px] mobile:py-24;
  &-header {
    @apply flex flex-col gap-2 pb-20;
    @apply mobile:pb-8;
  }
  &-title {
    @apply text-lg font-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.5px;
  }
  &-subtitle {
    @apply text-base;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: left;
  }
  &-body {
    @apply py-6;
  }
  &-footer {
    @apply flex justify-between gap-10 w-full;
    &-button {
      @apply w-full text-base;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      &.close {
        @apply text-white wi-[32] he-[32] mx-auto;
        position: absolute;
        top: -32px;
        right: 0;
      }
      &.complete {
        @apply text-white;
        background: #005aa9;
        padding: 7px 0 9px;
      }
    }
  }
}
</style>

import { ERROR_MESSAGES as CORE_ERROR_MESSAGES } from '@core/constants';

const ccName = import.meta.env.VITE_APP_CC_NAME;

export enum ERROR_MESSAGES_RESERVATION {
  UNOPENED = '오픈전입니다.',
  END = '마감되었습니다.',
  PAST = '마감되었습니다.',
  CLOSE = '휴장입니다.',
}

export const ERROR_MESSAGES: { [key: string]: string } = {
  ...CORE_ERROR_MESSAGES,
  INTERCEPTED_ID: `회원님은 홈페이지 사용이 제한된 상태입니다.<br />
홈페이지 사용을 원하시면<br /> ${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_WEB_ID: `아이디가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  NOT_EXIST_MEMBER: `입력하신 회원번호의 회원이 존재하지 않습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_MEMBER: `회원정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_MEMBERSHIP: `회원권정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  PENALTY_PERIOD:
    '위약기간중입니다 <br/> :minPunishStartDate ~ :maxPunishEndDate 까지 <br/> 위약기간입니다',
};

export enum ROUTE_NAME {
  Main = 'Main', // 메인
  NotFound = 'NotFound', // 페이지없음
  RepairHomepage = 'RepairHomepage', // 시스템점검
  // auth
  Signin = 'Signin', // 로그인
  SigninAdmin = 'SigninAdmin', // 관리자로그인
  SignupAgree = 'SignupAgree', // 회원가입약관동의
  SignupCertification = 'SignupCertification', // 회원가입인증
  Signup = 'Signup', // 회원가입
  SignupComplete = 'SignupComplete', // 회원가입완료
  MyInfo = 'MyInfo', //마이페이지
  FindId = 'FindId', // ID 찾기
  FindPassword = 'FindPassword', // PW 찾기
  NewPasswordAccount = 'NewPasswordAccount', // 신규비밀번호생성

  //agree
  ServiceTerms = 'ServiceTerms', // 이용약관
  InternetTerms = 'InternetTerms', // 인터넷이용약관
  PrivacyTerms = 'PrivacyTerms', // 개인정보 처리방침
  PrivacyEmail = 'PrivacyEmail', // 이메일 무단수집거부
  VideoInformation = 'VideoInformation', //영상정보처리기기 운영관리방침

  //클럽소개 club-info
  ClubInfo = 'ClubInfo', // 클럽소개
  ClubIntroduction = 'ClubIntroduction', // 클럽소개
  Greeting = 'Greeting', // 인사말
  CountryClubIntroduction = 'CountryClubIntroduction', // 컨트리클럽소개
  Location = 'Location', // 오시는길

  //코스소개 course-info
  CourseInfo = 'CourseInfo', // 코스소개
  CourseIntroduction = 'CourseIntroduction', // 코스소개
  SunCourse = 'SunCourse', // 햇님코스
  MoonCourse = 'MoonCourse', // 달님코스
  StarCourse = 'StarCourse', // 별님코스

  //이용안내 use-information
  UseInformation = 'UseInformation', // 이용안내
  UsageInformation = 'UsageInformation', // 이용안내
  Etiquette = 'Etiquette', //  에티켓
  FeeInformation = 'FeeInformation', // 요금안내
  TermsOfUse = 'TermsOfUse', // 이용규정
  LocalRule = 'LocalRule', // 로컬룰
  ReservationInformation = 'ReservationInformation', // 예약안내
  AgreeTerms = 'AgreeTerms', // 약관동의

  //인터넷예약 internet-resve
  InternetReservation = 'InternetReservation', // 인터넷예약
  GroupReservation = 'GroupReservation', // 단체예약
  YearGroupReservationApplication = 'YearGroupReservationApplication', // 연단체 신청
  OneTimeGroupReservationApplication = 'OneTimeGroupReservationApplication', // 일회성단체 신청
  Reservation = 'Reservation', // 실시간예약
  ReservationModify = 'ReservationModify', // 실시간예약변경
  ReservationGroupFormation = 'ReservationGroupFormation', // 조편성
  NoCaddieReservation = 'NoCaddieReservation', // 예약하기
  ReservationStatus = 'ReservationStatus', // 예약확인/취소

  // 부대시설
  Facilities = 'Facilities', // 부대시설
  ClubHouse = 'ClubHouse', // 클럽하우스
  Restaurant = 'Restaurant', // 레스토랑
  LockerRoom = 'LockerRoom', // 로커룸

  // 커뮤니티
  Community = 'Community', // 커뮤니티
  Notice = 'Notice', // 공지사항
  NoticeDetail = 'NoticeDetail', // 공지사항 상세
  NoticeWrite = 'NoticeWrite', // 공지사항 작성
  NoticeModify = 'NoticeModify', // 공지사항 작성
  Banner = 'Banner', // 배너
  BannerWrite = 'BannerWrite', // 배너 작성
  BannerModify = 'BannerModify', // 배너 수정
  Archive = 'Archive', // 자료실
  ArchiveView = 'ArchiveView', // 자료실 상세
  ArchiveWrite = 'ArchiveWrite', // 자료실 작성
  ArchiveModify = 'ArchiveModify', // 자료실 작성
  PhotoGallery = 'PhotoGallery', // 포토갤러리
  HallOfFame = 'HallOfFame', // 명예의전당
  //정보마당 infoForum-forum
  // Notification = 'Notification', // 클럽소식
  // NotificationView = 'NotificationView', // 클럽소식
  // PhotoGallery = 'PhotoGallery', // 포토갤러리
  // HallOfFame = 'HallOfFame', // 명예의전당
  // WeatherInfo = 'WeatherInfo', // 날씨정보

  //고객라운지 customer-lounge
  CustomerLounge = 'CustomerLounge',

  // VisitStatus = 'VisitStatus', // 내장현황
  // ReservePenaltyStatus = 'ReservePenaltyStatus', // 위약현황
  // ScoreStatus = 'ScoreStatus', // 스코어 현황
  MyInfoModify = 'MyInfoModify', // 회원정보 수정

  ReserveView = 'ReserveView', // 실시간예약
  WaitingReserveView = 'WaitingReserveView', // 대기예약
  ReserveModifyView = 'ReserveModifyView', // 예약변경
  //고객의 소리 voc
  Recommend = 'Recommend', // 고객의 소리
  Withdrawal = 'Withdrawal', // 회원탈퇴

  MyPage = 'MyPage',
  UserInfoModify = 'UserInfoModify', // 내정보
  GroupReserveInfo = 'GroupReserveInfo', //단체신청내역
  ScoreHandicapInfo = 'ScoreHandicapInfo', // 스코어/핸디캡
  PenaltyInfo = 'PenaltyInfo', //위약현황
  CheckinInfo = 'CheckinInfo', //내장현황
}

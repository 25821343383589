import { AxiosAdapter as CoreAxiosAdapter } from '@core/adapters';
import { ROUTE_NAME } from '@constants';
import { AxiosResponse } from 'axios';
import { isArray } from 'lodash-es';

export class AxiosAdapter extends CoreAxiosAdapter {
  async handle400Error(
    errorMessage: string,
    errorCode: string,
    error: AxiosResponse
  ) {
    console.error('400 Bad Request Error', error);
    if (errorCode === 'NO_PASSWORD') {
      if (!(await this.alertStore.showConfirmDialog(errorMessage))) {
        return;
      }
      await this.router.push({ name: ROUTE_NAME.NewPasswordAccount });
    } else if (isArray(error.data.data)) {
      throw error;
    } else {
      await this.alertStore.showAlertDialog(errorMessage);
    }
  }
}
